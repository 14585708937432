// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../.yarn/__virtual__/css-loader-virtual-fd0f9164f3/0/cache/css-loader-npm-6.11.0-d945f9f4c0-9e3665509f.zip/node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../.yarn/__virtual__/css-loader-virtual-fd0f9164f3/0/cache/css-loader-npm-6.11.0-d945f9f4c0-9e3665509f.zip/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root{--breakpoint-xs:320px;--breakpoint-sm:574px;--breakpoint-md:767px;--breakpoint-lg:990px;--breakpoint-xl:1199px;--breakpoint-max-height:600px}.xFr2D{margin:auto auto 8px;max-width:500px;position:relative}.xFr2D,.aupph{display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center}.aupph{font-size:12px;font-size:.75rem;-ms-flex-positive:1;flex-grow:1;-ms-flex-pack:end;-webkit-column-gap:8px;-moz-column-gap:8px;column-gap:8px;justify-content:flex-end}.V6fBz{color:#797979;font-size:12px}.dSgOf{background-color:transparent;border:1px solid transparent;color:#2c6ca7;display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;border-radius:16px;-webkit-column-gap:4px;-moz-column-gap:4px;column-gap:4px;font-weight:600}.dSgOf span{height:22px;line-height:24px;width:22px}.dSgOf span:first-of-type{font-size:20px}.dSgOf:hover{background-color:#fff;border:1px solid #2c6ca7;cursor:pointer}@media (max-width:1199px){.aupph.fBu2h{position:inherit}}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `xFr2D`,
	"settings": `aupph`,
	"title": `V6fBz`,
	"walletButton": `dSgOf`,
	"fixed": `fBu2h`
};
module.exports = ___CSS_LOADER_EXPORT___;

import React, { FunctionComponent, HTMLAttributes } from 'react';
import cx from 'classnames';
import useStyles from 'isomorphic-style-loader-react18/useStyles';
import s from './Panel.less';

const Panel: FunctionComponent<
  HTMLAttributes<HTMLDivElement> & {
    secondary?: boolean;
    success?: boolean;
    info?: boolean;
    warning?: boolean;
    danger?: boolean;
    guide?: boolean;
    outlined?: boolean;
  }
> = ({
  className = '',
  secondary = false,
  success = false,
  info = false,
  warning = false,
  danger = false,
  guide = false,
  outlined = false,
  children,
  ...otherProps
}) => {
  useStyles(s);
  const styleModifier = {
    [s.secondary]: secondary,
    [s.success]: success,
    [s.info]: info,
    [s.warning]: warning,
    [s.danger]: danger,
    [s.guide]: guide,
    [s.outlined]: outlined,
  };

  return (
    <div className={cx(s.root, className, styleModifier)} {...otherProps}>
      {children}
    </div>
  );
};

export default Panel;

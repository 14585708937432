import Button from 'components/Button/Button';
import LinkAccount from 'customer/components/emoney/Account/Link';
import useStyles from 'isomorphic-style-loader-react18/useStyles';
import { FC } from 'react';
import s from './Welcome.less';
import welcomeImg from './splash.svg';

const Welcome: FC = () => {
  useStyles(s);

  return (
    <div className={s.container}>
      <div
        className={s.greetingPanel}
        style={{ backgroundImage: `url(${welcomeImg})` }}
      >
        <div className={s.greetingText}>
          <h4 className={s.greetingTitle}>Welcome to Monerium</h4>
          <p className={s.greetingSub}>
            Let’s create a personal or business IBAN for your web3 wallet to
            unlock SEPA euro payments.
            <br />
            <br />
            First step? Connect your wallet!
          </p>

          <LinkAccount
            trigger={
              <Button
                style={{ maxWidth: '250px' }}
                info
                title="Connect a wallet"
              >
                CONNECT WALLET
              </Button>
            }
          />
        </div>
      </div>
    </div>
  );
};

export default Welcome;

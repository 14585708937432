// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../.yarn/__virtual__/css-loader-virtual-fd0f9164f3/0/cache/css-loader-npm-6.11.0-d945f9f4c0-9e3665509f.zip/node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../.yarn/__virtual__/css-loader-virtual-fd0f9164f3/0/cache/css-loader-npm-6.11.0-d945f9f4c0-9e3665509f.zip/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root{--breakpoint-xs:320px;--breakpoint-sm:574px;--breakpoint-md:767px;--breakpoint-lg:990px;--breakpoint-xl:1199px;--breakpoint-max-height:600px}.KukPx{cursor:default}.Bwkzc{display:-ms-flexbox;display:flex;min-height:75px;padding:0 calc(var(--unit)*2);-ms-flex-align:center;align-items:center;background:var(--default-color);color:var(--font-color-default)}.rLXhS{display:block;-ms-flex-positive:1;flex-grow:1}@media (max-width:320px){.Bwkzc{min-height:50px;padding:calc(var(--unit))}}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"settingsView": `KukPx`,
	"accountCurrencyDetail": `Bwkzc`,
	"accountBalance": `rLXhS`
};
module.exports = ___CSS_LOADER_EXPORT___;

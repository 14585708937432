// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../.yarn/__virtual__/css-loader-virtual-fd0f9164f3/0/cache/css-loader-npm-6.11.0-d945f9f4c0-9e3665509f.zip/node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../.yarn/__virtual__/css-loader-virtual-fd0f9164f3/0/cache/css-loader-npm-6.11.0-d945f9f4c0-9e3665509f.zip/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.W85m6{display:-ms-flexbox;display:flex;-ms-flex-pack:center;justify-content:center}._e5kt{background-attachment:scroll;background-color:#fff;background-position:50% 0;background-repeat:no-repeat;background-size:auto;border-radius:20px;-webkit-box-shadow:0 2px 3px rgba(0,0,0,.15);box-shadow:0 2px 3px rgba(0,0,0,.15);max-width:400px;min-height:280px;padding:280px 30px 30px;text-align:center}.KtKZF{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;-ms-flex-align:center;align-items:center}.oT60u{font-weight:900;text-align:center}.dirBC,.oT60u{margin-bottom:30px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `W85m6`,
	"greetingPanel": `_e5kt`,
	"greetingText": `KtKZF`,
	"greetingTitle": `oT60u`,
	"greetingSub": `dirBC`
};
module.exports = ___CSS_LOADER_EXPORT___;

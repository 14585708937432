import React, { FC, useEffect, useState } from 'react';
import cx from 'classnames';
import withStyles from 'isomorphic-style-loader-react18/withStyles';
import s from './Static.less';

const LoadingStatic: FC<{
  dialog?: string;
  small?: boolean;
  displayBuffer?: boolean;
}> = ({ dialog = 'Loading', small = false, displayBuffer = true }) => {
  const [show, setShow] = useState(!displayBuffer);
  useEffect(() => {
    const timer = setTimeout(() => {
      setShow(true);
    }, 200);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  if (!show) return null;
  return (
    <div className={cx(s.container, { [s.small]: small })}>
      <div className={s.dialog}>
        <div className={s.indicator}>
          <div />
          <div />
          <div />
        </div>
        <h1>{dialog}</h1>
      </div>
    </div>
  );
};

export default withStyles(s)(LoadingStatic);

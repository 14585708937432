import { Verification } from '../Verification/types';
import { BeneficialOwner, Director, User } from './types';

const removeDuplicates = (arr: Verification[]): Verification[] => {
  const map = new Map<string, Verification>();
  arr.forEach((verification) => {
    if (!map.has(verification.id)) {
      map.set(verification.id, verification);
    }
  });
  return Array.from(map.values());
};

// eslint-disable-next-line import/prefer-default-export
export const userToDirectorOrBeneficialOwner = (
  user: User,
  verifications?: Verification[],
  ownershipPercentage?: string,
): Director | BeneficialOwner => {
  const attributesToCopy: (keyof User & keyof Director)[] = [
    'id',
    'firstName',
    'lastName',
    'email',
    'birthday',
    'nationality',
    'country',
    'countryState',
    'postalCode',
    'city',
    'address',
  ];
  const result: Partial<Director> & Partial<BeneficialOwner> = {};

  attributesToCopy.forEach((attribute) => {
    if (attribute in user) {
      result[attribute] = user[attribute] as any;
    }
  });

  if (verifications && verifications.length > 0) {
    result.verifications = removeDuplicates(verifications);
  }

  if (ownershipPercentage !== undefined) {
    result.ownershipPercentage = ownershipPercentage;
    return result as BeneficialOwner;
  }
  return result as Director;
};

export const needsInfo = (user: User) => {
  const requiredInfo = [
    'email',
    'country',
    'postalCode',
    'city',
    'address',
    'privacy',
    'usCitizen',
    'usTaxPerson',
    'verifications',
  ];
  return user && !requiredInfo.every((info) => info in user);
};

export const sameVerificationIds = (
  personVerifications: Verification[],
  userVerification: Verification[],
): boolean => {
  return userVerification.every(
    (userV) =>
      personVerifications.find(
        (personV) => personV.kind === userV.kind && personV.id === userV.id,
      ) !== undefined,
  );
};

export const replaceVerifications = (
  persons: Director[] | BeneficialOwner[],
  personId: string,
  newVerifications: Verification[],
): Director[] | BeneficialOwner[] => {
  return persons.map((p) => {
    if (p.id === personId) {
      return { ...p, verifications: newVerifications };
    }
    return p;
  });
};

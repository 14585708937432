// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../.yarn/__virtual__/css-loader-virtual-fd0f9164f3/0/cache/css-loader-npm-6.11.0-d945f9f4c0-9e3665509f.zip/node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../.yarn/__virtual__/css-loader-virtual-fd0f9164f3/0/cache/css-loader-npm-6.11.0-d945f9f4c0-9e3665509f.zip/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root{--breakpoint-xs:320px;--breakpoint-sm:574px;--breakpoint-md:767px;--breakpoint-lg:990px;--breakpoint-xl:1199px;--breakpoint-max-height:600px}.EzYF4{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;margin:15px auto 0;max-width:500px;-ms-flex-pack:center;justify-content:center}.EzYF4 .V6wDh{position:relative}.EzYF4 .V6wDh .rgMxi{background-color:var(--success-background-color);border-radius:50%;display:block;height:10px;position:absolute;right:15px;top:15px;width:10px}.VvDpv{display:-ms-flexbox;display:flex;width:100%;-ms-flex-pack:end;justify-content:flex-end;-ms-flex-item-align:end;align-self:flex-end;-ms-flex-direction:column;flex-direction:column;font-size:22.4px;font-size:1.4rem;margin-right:15px;-ms-flex-align:end;align-items:flex-end}.VvDpv small{color:#757575;font-size:12px;font-size:.75rem}.niHrs{height:48px;margin-left:15px}.FhSPE{display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;color:var(--font-color-light);font-size:22.4px;font-size:1.4rem;font-weight:500;gap:5px;margin-bottom:15px}@media (max-width:320px){.EzYF4 .V6wDh .rgMxi{height:8px;right:10px;top:10px;width:8px}}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sectionWrapper": `EzYF4`,
	"cardWrapper": `V6wDh`,
	"connectedIndicator": `rgMxi`,
	"header": `VvDpv`,
	"icon": `niHrs`,
	"balance": `FhSPE`
};
module.exports = ___CSS_LOADER_EXPORT___;

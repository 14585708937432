// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../.yarn/__virtual__/css-loader-virtual-fd0f9164f3/0/cache/css-loader-npm-6.11.0-d945f9f4c0-9e3665509f.zip/node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../.yarn/__virtual__/css-loader-virtual-fd0f9164f3/0/cache/css-loader-npm-6.11.0-d945f9f4c0-9e3665509f.zip/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root{--breakpoint-xs:320px;--breakpoint-sm:574px;--breakpoint-md:767px;--breakpoint-lg:990px;--breakpoint-xl:1199px;--breakpoint-max-height:600px}.I_vua{background:#fff;border:none;border-radius:var(--border-radius);-webkit-box-shadow:0 5px 5px rgba(0,0,0,.04);box-shadow:0 5px 5px rgba(0,0,0,.04);margin:0 0 1rem;padding:calc(1.3125rem + .75vw)}@media (min-width:1200px){.I_vua{padding:1.875rem}}.e6ioj{background-color:#e4f7e8;border-color:#e4f7e8;color:#57a365}.LqWm5,.e6ioj{-webkit-box-shadow:none;box-shadow:none}.LqWm5{background-color:#e2ecfa;border-color:#e2ecfa;color:#48798c}.NSscf{background-color:var(--warning-panel-background-color);border-color:var(--warning-panel-background-color);-webkit-box-shadow:none;box-shadow:none;color:var(--warning-panel-color)}.NSscf b{font-weight:400}.wpI8E{background-color:#ffeeed;border-color:#ffeeed;-webkit-box-shadow:none;box-shadow:none;color:#d6625d}@media (max-width:574px){.I_vua{border-radius:0;-webkit-box-shadow:none;box-shadow:none;padding-left:.75rem;padding-right:.75rem}}@media print{.I_vua{background:#fff;border:none;border-radius:0;-webkit-box-shadow:none;box-shadow:none}}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `I_vua`,
	"success": `e6ioj`,
	"info": `LqWm5`,
	"warning": `NSscf`,
	"danger": `wpI8E`
};
module.exports = ___CSS_LOADER_EXPORT___;

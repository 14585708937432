import React from 'react';
import { AppRouteAction } from 'src/customer/types';
import Account from 'customer/components/emoney/Account/Account';
import { SettingsProvider } from 'customer/components/emoney/Account/Settings/SettingsProvider';
import Layout from 'customer/components/Layout';

const action: AppRouteAction = () => ({
  chunks: ['accounts'],
  title: 'Accounts',
  protected: true,
  component: (
    <Layout isProtected>
      <SettingsProvider>
        <Account />
      </SettingsProvider>
    </Layout>
  ),
});

export default action;

import cx from 'classnames';
import useStyles from 'isomorphic-style-loader-react18/useStyles';
import React from 'react';
import Panel from 'src/customer/components/Layout/Panel';
import history from 'utils/history';
import Balance from '../Balance/Balance';
import { useSettingsContext } from '../Settings/SettingsProvider';
import { Account } from '../types';
import s from './Card.less';

const handleAccountSelect = (
  account: Account,
  e: React.KeyboardEvent | React.MouseEvent,
) => {
  e.preventDefault();
  // Number 13 is the "Enter" key on the keyboard
  const key =
    (e as React.KeyboardEvent)?.key || (e as React.KeyboardEvent)?.keyCode;
  if (key && key !== 13) {
    // Cancel the default action, if needed
    return false;
  }
  history?.push(`/accounts/${account.id}`);
  return false;
};

const AccountCard = ({ account }: { account: Account }) => {
  useStyles(s);
  const { id } = account;
  const { settingsView } = useSettingsContext();

  // Hide accounts, unless settingsView is on.
  if (!settingsView && !account?.isVisible) {
    return null;
  }

  return (
    <a
      key={`account-small-${id}`}
      href={`/accounts/${id}`}
      tabIndex={0}
      className="btn-reset"
      onClick={(e) => {
        if (settingsView) {
          e.preventDefault();
        } else {
          handleAccountSelect(account, e);
        }
      }}
      onKeyUp={(e) => handleAccountSelect(account, e)}
    >
      <Panel className={cx({ [s.settingsView]: settingsView })}>
        <div className={s.accountCurrencyDetail}>
          <div className={s.accountBalance}>
            <Balance showIcon account={account} />
          </div>
        </div>
      </Panel>
    </a>
  );
};
export default AccountCard;

import React, { FC, HTMLAttributes } from 'react';
import cx from 'classnames';
import useStyles from 'isomorphic-style-loader-react18/useStyles';
import s from './Panel.less';

const Panel: FC<
  HTMLAttributes<HTMLDivElement> & {
    className?: string;
    success?: boolean;
    warning?: boolean;
    info?: boolean;
    danger?: boolean;
  }
> = ({
  children,
  className = '',
  success = false,
  info = false,
  warning = false,
  danger = false,

  ...otherProps
}) => {
  useStyles(s);
  return (
    <div
      className={cx(s.root, className, {
        [s.success]: success,
        [s.info]: info,
        [s.warning]: warning,
        [s.danger]: danger,
      })}
      {...otherProps}
    >
      {children}
    </div>
  );
};

export default Panel;

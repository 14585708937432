// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../.yarn/__virtual__/css-loader-virtual-fd0f9164f3/0/cache/css-loader-npm-6.11.0-d945f9f4c0-9e3665509f.zip/node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../.yarn/__virtual__/css-loader-virtual-fd0f9164f3/0/cache/css-loader-npm-6.11.0-d945f9f4c0-9e3665509f.zip/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@-webkit-keyframes sAtkC{0%{background-color:#2c6ca7;height:51px;top:6px}50%,to{background-color:#3a4759;height:26px;top:19px}}@keyframes sAtkC{0%{background-color:#2c6ca7;height:51px;top:6px}50%,to{background-color:#3a4759;height:26px;top:19px}}@-webkit-keyframes AgdRE{0%{background-color:#2c6ca7;height:25px;top:6px}50%,to{background-color:#3a4759;height:10px;top:19px}}@keyframes AgdRE{0%{background-color:#2c6ca7;height:25px;top:6px}50%,to{background-color:#3a4759;height:10px;top:19px}}.oasq7{background:transparent}.mfnI3{color:#fff;display:-ms-flexbox;display:flex;height:100%;text-align:center;-ms-flex-pack:center;-ms-flex-flow:column nowrap;flex-flow:column nowrap;justify-content:center}.mfnI3>h1{color:#3a4759;font-size:14px;margin:0}.sAtkC{height:64px;margin:0 auto;position:relative;width:64px}.sAtkC div{-webkit-animation:sAtkC 1.2s cubic-bezier(0,.5,.5,1) infinite;animation:sAtkC 1.2s cubic-bezier(0,.5,.5,1) infinite;background:#fff;left:6px;position:absolute;width:13px}.sAtkC div:first-child{-webkit-animation-delay:-.24s;animation-delay:-.24s;left:6px}.sAtkC div:nth-child(2){-webkit-animation-delay:-.12s;animation-delay:-.12s;left:26px}.sAtkC div:nth-child(3){-webkit-animation-delay:0;animation-delay:0;left:45px}.ofx4q .mfnI3>h1{display:none}.ofx4q .sAtkC{height:30px;width:64px}.ofx4q .sAtkC div{-webkit-animation:AgdRE 1.2s cubic-bezier(0,.5,.5,1) infinite;animation:AgdRE 1.2s cubic-bezier(0,.5,.5,1) infinite;background:#fff;left:6px;width:13px}.ofx4q .sAtkC div:first-child{-webkit-animation-delay:-.24s;animation-delay:-.24s;left:6px}.ofx4q .sAtkC div:nth-child(2){-webkit-animation-delay:-.12s;animation-delay:-.12s;left:26px}.ofx4q .sAtkC div:nth-child(3){-webkit-animation-delay:0;animation-delay:0;left:45px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `oasq7`,
	"dialog": `mfnI3`,
	"indicator": `sAtkC`,
	"small": `ofx4q`,
	"smallIndicator": `AgdRE`
};
module.exports = ___CSS_LOADER_EXPORT___;
